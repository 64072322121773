import React, { FC } from "react";
import Layout from "../Layout";
import { BreadCrumb } from "../Shared/BreadCrumb";
import { PortfolioList } from "./PortfolioList";

interface OurWorkProps {
  footerContent: any;
  productsDeveloped: any;
}

const OurWork: FC<OurWorkProps> = ({ footerContent, productsDeveloped }) => {
  return (
    <Layout contentfulFooterContentForPages={footerContent}>
      <BreadCrumb
        activePath={{ routeName: "Zartek Portfolio" }}
        routingPaths={[{ path: "/", routeName: "Home" }]}
        title="Zartek Portfolio"
      />
      <section className="section section-lg pb-3">
        <div className="container">
          <div className="row row-50 row-xl-70">
            <PortfolioList productsDeveloped={productsDeveloped} />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OurWork;
