import Portfolio from "@/components/Portfolio";
import SEO from "@/components/SEO";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const OurWorkPage: FC<PageProps> = () => (
  <StaticQuery
    query={graphql`
      query OurWorkPage {
        contentfulOurWorkPage {
          #     SEO
          seo {
            documentTitle
            metaTitle
            metaUrl
            metaAuthor
            description {
              description
            }
            metaKeywords
          }

          productsDeveloped {
            id
            productTitle
            aboutProduct
            appStoreLink
            playStoreLink
            websiteLink
            productIcon {
              file {
                url
              }
            }
          }

          #     FOOTER
          footerContent {
            content {
              raw
            }
          }
        }
      }
    `}
    render={({
      contentfulOurWorkPage: {
        seo: {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        },
        footerContent,
        productsDeveloped,
      },
    }) => {
      return (
        <Fragment>
          <SEO
            contentfulSeo={{
              documentTitle,
              metaTitle,
              metaDescription: description,
              metaKeywords,
              metaUrl,
              metaAuthor: metaAuthor,
            }}
          />
          <Portfolio footerContent={footerContent} productsDeveloped={productsDeveloped} />
        </Fragment>
      );
    }}
  />
);

export default OurWorkPage;
