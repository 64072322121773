import React, { FC } from "react";
import { LazyImage } from "../Helpers/LazyImage";

interface ProductInterfaceProps {
  url: string;
  productTitle: string;
  appStoreLink?: string;
  playStoreLink?: string;
  websiteURL?: string;
  aboutProduct: string;
}

export const Product: FC<ProductInterfaceProps> = ({
  url,
  productTitle,
  appStoreLink,
  playStoreLink,
  websiteURL,
  aboutProduct,
}) => {
  return (
    <div className="col-sm-6 col-lg-4 col-xl-3">
      <article className="profile-modern">
        <figure className="profile-modern-figure">
          <LazyImage height={264} width={264} src={url} alt="portfolio" />
        </figure>
        <div className="profile-modern-main">
          <div className="profile-modern-main-item profile-modern-main-item_primary">
            <h4 className="profile-modern-name">{productTitle}</h4>
            <p className="profile-modern-position">
              {appStoreLink && !playStoreLink && !websiteURL ? "iOS" : ""}
              {playStoreLink && !appStoreLink && !websiteURL ? "Android" : ""}
              {websiteURL && !playStoreLink && !appStoreLink ? "Web" : ""}
              {appStoreLink && playStoreLink && !websiteURL ? "iOS, Android" : ""}
              {appStoreLink && websiteURL && !playStoreLink ? "iOS, Web" : ""}
              {playStoreLink && websiteURL && !appStoreLink ? "Android, Web" : ""}
              {playStoreLink && websiteURL && appStoreLink ? "iOS, Android, Web" : ""}
            </p>
          </div>
          <div className="profile-modern-main-item profile-modern-main-item_secondary">
            <h4 className="profile-modern-main-title extra-lighter">{aboutProduct}</h4>
            <ul className="list-inline list-inline-xs">
              {appStoreLink && (
                <li>
                  <a
                    className="icon icon-md mdi mdi-apple"
                    href={appStoreLink}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
              )}
              {playStoreLink && (
                <li>
                  <a
                    className="icon icon-md mdi mdi-google-play"
                    href={playStoreLink}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
              )}
              {websiteURL && (
                <li>
                  <a
                    className="icon icon-md mdi mdi-web"
                    href={websiteURL}
                    target="_blank"
                    rel="noreferrer"
                  ></a>
                </li>
              )}
            </ul>
            <div className="profile-modern-toggle mdi mdi-arrow-top-right" />
          </div>
        </div>
      </article>
    </div>
  );
};
