import React, { FC } from "react";
import { Product } from "./Product";

export const PortfolioList: FC<{ productsDeveloped: any }> = ({ productsDeveloped }) => {
  return productsDeveloped?.map(
    ({
      id,
      productTitle,
      aboutProduct,
      appStoreLink,
      playStoreLink,
      websiteLink,
      productIcon: {
        file: { url: productIcon },
      },
    }) => {
      return (
        <Product
          key={id}
          aboutProduct={aboutProduct}
          productTitle={productTitle}
          url={productIcon}
          appStoreLink={appStoreLink}
          websiteURL={websiteLink}
          playStoreLink={playStoreLink}
        />
      );
    },
  );
};
